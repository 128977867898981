<template>
  <section id="Contactanos" data-aos="fade-up">
    <img
      src="@/assets/img/top-curva-desktop.png"
      class="w-100"
      alt="Fondo programas 1 (desktop)"
      loading="lazy"
    />
    <div class="bg-light-green py-5 contact">
      <div class="__content">
        <div class="__description">
          <h2>
            <b>{{ data.title }}</b>
          </h2>
          <h4>{{ data.description }}</h4>
        </div>
        <form class="needs-validation is-valid w-100" @submit="checkForm">
          <div class="row">
            <div class="col-12 col-md-6">
              <div class="form-group wet-form">
                <label class="form-label">{{ data.fields.name.text }} </label>
                <input
                  v-model="Form.name"
                  :placeholder="data.fields.name.placeholder"
                  type="text"
                  class="form-control"
                  required
                />
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group wet-form">
                <label class="form-label">{{ data.fields.company.text }}</label>
                <input
                  v-model="Form.company"
                  :placeholder="data.fields.company.placeholder"
                  class="form-control"
                  type="text"
                  required
                />
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group wet-form">
                <label class="form-label">{{ data.fields.email.text }} </label>
                <input
                  v-model="Form.email"
                  :placeholder="data.fields.email.placeholder"
                  type="email"
                  class="form-control"
                  required
                />
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group wet-form">
                <label class="form-label">{{ data.fields.charge.text }}</label>
                <input
                  v-model="Form.charge"
                  :placeholder="data.fields.charge.placeholder"
                  type="text"
                  class="form-control"
                  required
                />
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group wet-form">
                <label class="form-label">{{ data.fields.phone.text }}</label>
                <vue-phone-number-input
                  v-model="Form.phone"
                  @update="handleInput"
                  v-bind="vuePhone.props"
                ></vue-phone-number-input>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group wet-form">
                <label class="form-label">{{ data.fields.website.text }}</label>
                <input
                  v-model="Form.website"
                  :placeholder="data.fields.website.placeholder"
                  type="text"
                  class="form-control"
                  required
                />
              </div>
            </div>
            <div class="col-12 col-md-6">
              <!--              <div class="form-group wet-form">-->
              <!--                <label class="form-label">{{ data.fields.country.text }}</label>-->
              <!--                <input v-model="Form.country" :placeholder="data.fields.country.placeholder"-->
              <!--                       class="form-control" type="text" required>-->
              <!--              </div>-->
              <div class="form-group wet-form">
                <label class="form-label">{{ data.fields.country.text }}</label>
                <div class="d-flex">
                  <country-flag
                    class="mx-2"
                    v-if="Form.country"
                    :country="Form.country.code"
                    size="normal"
                  />
                  <select
                    class="form-select"
                    name="country-select"
                    required
                    id="countrySelect"
                    v-model="Form.country"
                  >
                    <option
                      v-for="country in countries"
                      :key="country.code"
                      :value="country"
                    >
                      {{ country.name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group wet-form">
                <label class="form-label">{{ data.fields.city.text }}</label>
                <input
                  v-model="Form.city"
                  :placeholder="data.fields.city.placeholder"
                  class="form-control"
                  type="text"
                  required
                />
              </div>
            </div>
            <div class="col-12">
              <div class="form-group wet-form">
                <label class="form-label">{{
                  data.fields.comments.text
                }}</label>
                <textarea
                  class="form-control"
                  v-model="Form.comments"
                  :placeholder="data.fields.comments.placeholder"
                  rows="5"
                  required
                ></textarea>
              </div>
            </div>
          </div>
          <div class="mt-4">
            <button
              class="btn btn-lg btn-success"
              style="width: 100%"
              type="submit"
            >
              {{ data.saveButton }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import Vue from "vue";
import store from "../../store";
import { SendContactForm } from "../../helpers/auth";
import { countriesList } from "../../utils/countries";
import CountryFlag from "vue-country-flag";

Vue.component("vue-phone-number-input", VuePhoneNumberInput);
Vue.component("country-flag", CountryFlag);
// Vue.use(VueCountryCode);

export default {
  name: "Contacto",
  data() {
    return {
      countries: countriesList,
      data: {
        title: "Queremos Conocerte",
        saveButton: "Guardar",
        description:
          "Nos interesa ver con que programa te podemos apoyar para impactar importantemente el futuro de tu empresa.",
        fields: {
          name: { text: "Nombre", placeholder: "Ingrese nombre de contacto" },
          company: {
            text: "Empresa",
            placeholder: "Ingrese nombre de la empresa",
          },
          email: { text: "Correo", placeholder: "Ingrese su email" },
          charge: { text: "Cargo", placeholder: "Escribir texto" },
          phone: { text: "Teléfono" },
          website: { text: "Sitio Web", placeholder: "Ingrese sitio web" },
          country: { text: "País", placeholder: "Ingrese pais " },
          comments: { text: "Comentarios", placeholder: "Escribir texto" },
          city: { text: "Ciudad", placeholder: "Ingrese ciudad" },
        },
        formattedNumber: "",
      },
      vuePhone: {
        props: {
          color: "#212529",
          "valid-color": "#23af4d",
          required: true,
          clearable: false,
          fetchCountry: true,
          preferredCountries: ["CL"],
          noExample: false,
          translations: {
            countrySelectorLabel: "Codigo de país",
            countrySelectorError: "Error",
            phoneNumberLabel: "Ingrese su número",
            example: "Ejemplo:",
          },
        },
      },
      Form: {
        name: null,
        company: null,
        email: null,
        charge: null,
        phone: null,
        website: null,
        country: null,
        city: null,
        comments: null,
        language: localStorage.language,
      },
    };
  },
  mounted() {
    this.fetchData();
  },
  watch: {
    "$store.state.sections": function () {
      this.fetchData();
    },
  },
  methods: {
    handleInput(e) {
      this.formattedNumber = e.formattedNumber;
    },
    fetchData() {
      if (store.state.sections.contact) {
        this.data = store.state.sections.contact.components;
      }
    },
    async checkForm(e) {
      e.preventDefault();
      this.$store.commit("ActivaLoader", true);
      const response = await SendContactForm({
        ...this.Form,
        language: store.getters.getLanguage,
        country: this.Form.country.name,
        phone: this.formattedNumber,
      });
      if (response.status === 200) {
        this.Form = {
          name: null,
          company: null,
          email: null,
          charge: null,
          phone: null,
          website: null,
          country: null,
          city: null,
          comments: null,
        };
      }

      this.$store.commit("ActivaLoader", false);
    },
  },
};
</script>

<style scoped lang="scss">
@use "src/assets/scss/colors";

.contact {
  display: flex;
  justify-content: center;
  width: 100%;

  .__content {
    align-items: center;
    display: flex;
    flex-direction: column;
    max-width: 1200px;
    padding: 20px;

    .btn-block {
      width: 100%;
      max-width: 400px;
    }
  }

  .__description {
    max-width: 70%;
    margin: 20px 0;
    text-align: center;
  }

  //.contactForm {
  //  display: grid;
  //  flex-wrap: wrap;
  //  grid-template-columns: 1fr;
  //  grid-gap: 24px;
  //  width: 100%;
  //  margin: 0;
  //}

  h2 {
    color: colors.$wet-green !important;
  }
}

.bg-light-green {
  background-color: #e7f8ec;
}

@media (min-width: 992px) {
  .contact {
    .contactForm {
      grid-template-columns: 1fr 1fr;
    }

    .__content {
      padding: 0;
    }
  }
}
</style>
